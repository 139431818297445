import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import small1 from "../images/small-1.jpg"
import small2 from "../images/small-2.jpg"
import Hero from "../components/hero";
import SocialMedia from "../components/social-media";
import {Helmet} from "react-helmet";
import schedule from "../images/youth-sched.png"


function YouthGroupClass(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | Youth Group Class</title>
            </Helmet>
            <Nav/>
            <Hero href={"/contact"} header={"Youth Athletic Development Class"}>
                <div id={"content-start"} className={"flex flex-col justify center max-w-5xl mx-auto px-8 pt-8 text-lg bg-white rounded-xl"}>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>Learn what it takes for athletes to go from good to great. Your athlete will become stronger, faster and jump higher as a bi-product of smart strength and conditioning training that keeps them SAFE. </p>
                    <iframe className={"py-5 mx-auto w-full lg:w-auto aspect-video"} width="560" height="315" src="https://www.youtube.com/embed/vUgz1roQhWg"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    <div className={"lg:flex lg:flex-row lg:justify-center "}>
                        <div className={"lg:flex lg:flex-row lg:justify-center "}>
                            <a className={"lg:px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started! </button></a>
                            <a className={"lg:px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                        </div>
                    </div>
                    <h2 className={"text-3xl text-center py-14"}>Schedule (subject to change)</h2>
                    <img src={schedule} alt={'schedule'}/>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>According to the NCAA the most common injuries seen in volleyball are ligament sprains and muscle strains (soft tissue). This strength program is designed with <span className={"font-bold italic"}>durability</span> as the number one priority.</p>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>Get a taste of what it is like and what it takes to become an elite level athlete. From understanding when to hit the gas and when to recover to learning the proper techniques that will keep them safe and perform at the top of their game. </p>
                    <div className="relative lg:w-[32rem] lg:h-[18rem] mx-auto pt-[56.25%] lg:pt-0 mb-14">
                        <iframe className="absolute inset-0 w-full h-full lg:h-[18rem]" src="https://www.youtube.com/embed/xycjt12igYg"></iframe>

                    </div>

                    <div className={"lg:flex lg:flex-row lg:justify-center "}>
                        <div className={"lg:flex lg:flex-row lg:justify-center "}>
                            <a className={"lg:px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started! </button></a>
                            <a className={"lg:px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                        </div>
                    </div>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 pt-4 mx-auto"}>Athletic skill acquisition for things like top end speed, change of direction, strength, motor control and vertical leap are built over time with principles of progression and adaptation to physical demand. </p>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}><span className={"font-bold italic"}>Too much too soon or inconsistency can lead to injury.</span> We want every athlete we work with to be <span className={"font-bold italic"}>successful</span> and have the <span className={"font-bold italic"}>opportunity</span> to put the work in that is necessary to see results. </p>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>We recommend that an athlete dedicate 1 hour twice a week to this program at a minimum of 8 weeks to give their body enough time to adapt and get MEASURABLE RESULTS. This program will build your child’s body to handle their sport and will complement the skills training on the court, field or track. </p>

                    <div className={"lg:flex lg:flex-row lg:justify-center pb-20"}>
                        <div className={"lg:flex lg:flex-row lg:justify-center "}>
                            <a className={"lg:px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started! </button></a>
                            <a className={"lg:px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                        </div>
                    </div>






                </div>
            </Hero>



            <Footer/>
        </div>
    )
}

export default YouthGroupClass